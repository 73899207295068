// 不可退类型
const NonRefundableType = {
  // 定制商品不可退
  Customized: 'Customized',
  // 品类不可退
  Category: 'Category',
  // 促销不可退
  Promotion: 'Promotion',
  // 低毛利不可退
  LowGrossMargin: 'LowGrossMargin',
  // 撤销权不可退（de市场）
  Withdrawal: 'Withdrawal',
  // 撤销权可退（促销不可退、低毛利不可退、品类不可退）（de市场）
  RefundableWithdrawal: 'RefundableWithdrawal'
}

// 不可退优先级
const nonRefundablePriority = {
  default: [
    NonRefundableType.Customized,
    NonRefundableType.Category,
    NonRefundableType.Promotion,
    NonRefundableType.LowGrossMargin
  ],
  de: [
    NonRefundableType.Customized,
    NonRefundableType.Withdrawal,
    NonRefundableType.RefundableWithdrawal
  ]
}

export {
  NonRefundableType,
  nonRefundablePriority
}
