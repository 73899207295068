<template>
  <div
    class="payment-bnpl-graph"
    :style="{ '--bnpl_color': options.color }"
  >
    <div
      class="wrap"
      :class="itemNum"
    >
      <div
        v-for="(item, idx) of list"
        :key="`item-${idx}`"
        class="item"
        :class="{ 'item-last': item.isLast, 'item-start': item.isStart }"
      >
        <div class="item__percent">
          <GraphRing
            :percent="item.percent"
            :color="options.color"
          />
        </div>
        <div class="item__info-wrap">
          <div class="item__amount">
            {{ item.amount }}
          </div>
          <div class="item__txt">
            {{ item.txt }}
          </div>
        </div>
      </div>
    </div>
    <!-- <TriangleIcon class="triangle-icon" /> -->
  </div>
</template>

<script>
// import TriangleIcon from './triangle_icon.vue'
import GraphRing from './item_info_bnpl_graph_ring.vue'
import { template as _commonTemplate } from '@shein/common-function'
export default {
  name: 'ItemInfoBnplGraph',
  components: {
    GraphRing
    // TriangleIcon
  },
  props: {
    scene: {
      type: String,
      default: 'checkout'
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    list() {
      return this.generateList()
    },
    itemNum() {
      let len = this.options.repayment_period_list.length
      if (len > 6) {
        return 'item-num-6'
      }
      return `item-num-${len}`
    }
  },
  methods: {
    template: _commonTemplate,
    pickLanguageTxt(num, unit) {
      switch (unit) {
        case 'day':
          return this.template(
            num,
            num === 1
              ? this.language.SHEIN_KEY_PC_30649
              : this.language.SHEIN_KEY_PC_30648
          )
        case 'week':
          return this.template(
            num,
            num === 1
              ? this.language.SHEIN_KEY_PC_30651
              : this.language.SHEIN_KEY_PC_30650
          )
        case 'month':
          return this.template(
            num,
            num === 1
              ? this.language.SHEIN_KEY_PC_30652
              : this.language.SHEIN_KEY_PC_30653
          )
      }
    },
    generateList() {
      let acc = 0
      let len = this.options.repayment_period_list.length
      return this.options.repayment_period_list.map((item, idx) => {
        // let txt = 'Today'

        let txt = this.language.SHEIN_KEY_PC_30647
        let isStart = true
        if (idx) {
          isStart = false
          txt = this.pickLanguageTxt(
            (item.current_period - 1) * this.options.repayment_period,
            this.options.repayment_period_unit
          )
        }
        acc += item.repayment_amount.amount
        return {
          key: `item-${idx}`,
          percent: (acc / this.options.amount) * 100,
          amount: item.repayment_amount.amountWithSymbol,
          txt,
          isStart,
          isLast: idx === len - 1
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.payment-bnpl-graph {
  position: relative;
  max-width: 480px;
  // height: 77px;
  border: 1px solid #d9d9d9;
  // border: 1px solid ;
  border-radius: 4px;
  margin-top: 10px;

  &::after {
    content: ' ';
    position: absolute;
    top: -5.5px;
    left: 35px;
    width: 10px;
    height: 10px;
    border: 1px solid #d9d9d9;
    border-top-left-radius: 1px;
    border-bottom: none;
    border-right: none;
    background-color: #fff;
    // rotate: 45deg;
    transform: rotate(45deg);
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 8px 12px;
    overflow-x: auto;
    // &::-webkit-scrollbar {
    //   width: 0;
    //   height: 0;
    // }
  }

  .item-num-2,
  .item-num-3 {
    padding: 7px 20px;
  }

  .item-num-2 .item {
    display: flex;
    .item__percent {
      margin-bottom: 2px;
    }
    .item__info-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 6px;
      margin-bottom: 2px;
    }
  }
  .item {
    min-width: 50px;
    flex: 0 0 auto;
    // flex: 1;
    line-height: 1;
    padding: 0 6px;
    .item__percent {
      display: flex;
      justify-content: center;
      margin-bottom: 6px;
    }
    .item__amount {
      text-align: center;
      color: var(---sui_color_gray_dark2, #666);
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .item__txt {
      text-align: center;
      color: var(---sui_color_gray_light1, #959595);
      font-size: 10px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      font-weight: 400;
      white-space: nowrap;
      font-family: 'SF Pro';
    }
  }
  .item-start {
    // margin-left: 20px;
    padding-left: 0;
    .item__amount {
      font-weight: 700;
      color: var(---sui_color_brand, #000);
    }
    .item__txt {
      font-weight: 700;
      color: var(--bnpl_color);
    }
  }
  .item-last {
    padding-right: 0;
    // margin-right: 20px;
  }

  // .triangle-icon{
  //   position: absolute;
  //   top: -5px;
  //   left: 35px;
  //   // width: 12px;
  //   // height: 12px;
  //   // border: 1px solid #D9D9D9;
  //   // border-bottom: none;
  //   // border-right: none;
  //   color:#D9D9D9;
  //   // fill:#D9D9D9;
  //   background-color: #fff;
  // }
}
</style>
