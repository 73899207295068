// 1:GST 2:ST 3:VAT 4:HST 5:PST 6:QST
/**
   * taxTypeConfig
   * 
   * @param {String} _name
   * @param {String} langKey
   * @return {Object}
   */
  
export const taxTypeConfig = {
  1: {
    _name: 'GST',
    langKey: 'SHEIN_KEY_PC_24357',
    hintKey: 'SHEIN_KEY_PC_26617'
  },
  2: {
    _name: 'ST',
    langKey: 'SHEIN_KEY_PC_16414',
  },
  3: {
    _name: 'VAT',
    langKey: 'SHEIN_KEY_PC_16416',
  },
  4: {
    _name: 'HST',
    langKey: 'SHEIN_KEY_PC_24358',
    hintKey: 'SHEIN_KEY_PC_26618'
  },
  5: {
    _name: 'PST',
    langKey: 'SHEIN_KEY_PC_24355',
    hintKey: 'SHEIN_KEY_PC_26615'
  },
  6: {
    _name: 'QST',
    langKey: 'SHEIN_KEY_PC_24356',
    hintKey: 'SHEIN_KEY_PC_26616'
  },
  // 目前仅巴西(BR)
  7: {
    _name: 'II',
    langKey: 'SHEIN_KEY_PC_26611',
    hintKey: 'SHEIN_KEY_PC_26612'
  },
  8: {
    _name: 'FVAT',
    langKey: 'SHEIN_KEY_PC_27348',
    hintKey: 'SHEIN_KEY_PC_27349'
  },
  9: {
    _name: 'IVAT',
    langKey: 'SHEIN_KEY_PC_27345',
    hintKey: 'SHEIN_KEY_PC_27346'
  }
}


export const getTaxesConfig = ({ taxTypes = [], rewriteConfig = {}  } = { taxTypes: [], rewriteConfig: {} }) => {
  const combnineTaxTypeConfig = Object.assign({}, taxTypeConfig, rewriteConfig)

  if (!taxTypes?.length) return combnineTaxTypeConfig
  return taxTypes.reduce((acc, cur) => ({ ...acc, [cur]: combnineTaxTypeConfig[cur] || {} }), {})
}

export const appendColon = str => str?.replace(/(.):?$/, '$1:')

// 手机号码脱敏
export function encryptPhoneNumber(v) {
  if (typeof v !== 'string') return v
  // 已脱敏
  if (v.includes('****')) return v

  const len = v.length

  if (len == 0) return v

  const markLen = Math.ceil(v.length / 2) // 脱敏长度
  const notLen = len - markLen

  const front = Math.ceil(notLen / 2)
  const end = notLen - front

  return v.slice(0, front) + '****' + v.slice(-end)
}

/**
 * 获取购买类型
 * 1 付费会员单独购，2 付费会员随单购，0无购买会员卡（或者超省卡）3超省卡单独购  4超省卡随单购 
 *
 * @export
 * @param {*} [orderInfo={}]
 * @return {*} number
 */
export function getBuyTp (orderInfo = {}) {
  const isBuyPrimeOrder = orderInfo?.order_type == 5 // 是否是付费会员单独购订单详情
  const isXtraOrder = orderInfo?.xtra_order_scene == 1 // 是否是超省卡单独购订单详情
  const isXtraWithOrder = orderInfo?.xtra_order_scene == 2 // 是否是超省卡随单购订单详情
  const isBuyPrimeWithOrder = () => {
    // 判断是否随单购
    const { orderGoodsList = [] } = orderInfo || {}
    const havePrimeGoods = orderGoodsList?.some(v => v.is_prime_goods)
    return havePrimeGoods && orderGoodsList.length > 1 ? 2 : 0
  }
  if (isBuyPrimeOrder) return 1
  if (isBuyPrimeWithOrder()) return 2
  if (isXtraOrder) return 3
  if (isXtraWithOrder) return 4
  return 0
}
