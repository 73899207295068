import { getTaxesConfig } from 'public/src/pages/common/helpers.js'

// apple pay 税费处理
function _apHandleTaxes(vm, taxes) {
  const language = vm.language || {}
  const { countryId, value } = vm.checkout?.default_address || {}
  // 是否是一单多税国家
  const isMultiTaxCountry = gbCommonInfo?.MULTIPLE_TAXES_SPLIT_DISPLAY?.includes(value)
  // ca站特殊处理
  const isShowCaGST = countryId === '38' || gbCommonInfo?.SiteUID == 'ca'
  const isBR = countryId == 30 || value == 'BR'
  const isTR = value == 'TR'
  const isMX = value == 'MX'

  const formatTaxes = (taxes = []) => {
    // 巴西站特殊处理
    // wiki.pageId=1167728623
    const rewriteConfig = isBR ? {
      3: {
        _name: 'ICMS',
        langKey: 'SHEIN_KEY_PC_26613',
      }
    } : {}
    let taxTypeConfig = getTaxesConfig({ rewriteConfig })
    if (!isMultiTaxCountry) {
      taxTypeConfig[1] = { ...taxTypeConfig[1], langKey: isShowCaGST ? 'SHEIN_KEY_PC_21334' : 'SHEIN_KEY_PC_16415' }
    }
    if (isTR) {
      taxTypeConfig[7] = { ...taxTypeConfig[7], langKey: 'SHEIN_KEY_PC_32484' }
    }
    if (isMX) {
      taxTypeConfig[7] = { ...taxTypeConfig[7], langKey: 'SHEIN_KEY_PC_21334' }
    }
    return taxes.map(tax => ({
      label: language?.[taxTypeConfig?.[tax?.taxType]?.langKey] || '',
      amount: tax.taxPrice?.amount
    }))
  }

  const personalTaxes = formatTaxes(taxes.filter(tax => tax?.taxPrice?.amount > 0 && tax?.taxPayType == 2))
  let companyTaxes = formatTaxes(taxes.filter(tax => tax?.taxPrice?.amount > 0 && tax?.taxPayType == 1))
  // wiki.pageId=1204228351 巴西站特殊处理, 不展示公司税
  if (isBR) companyTaxes = []

  return {
    personalTaxes,
    companyTaxes
  }
}

const _apHandlePrimeData = (orderGoodsList) => {
  let primeGoods = orderGoodsList?.filter(item => item.is_prime_goods) || []
  if (!primeGoods.length) return {}
  const { product = {}, avgPrice = null, unitPrice = {} } = primeGoods?.[0] || {}
  // 差价、原价、实际售价
  let sale_price = avgPrice ? avgPrice : unitPrice || {}

  return { discount_price: product?.prime_goods_discount_price, retail_price: product?.retailPrice, sale_price, hasPrime: true }
}

const _apHandleXtraGoodsPrice = (orderGoodsList = []) => {
  let xtraGoods = orderGoodsList?.filter(item => item.is_xtra_goods) || []
  if (!xtraGoods.length) return {}
  return +(xtraGoods?.[0]?.product?.salePrice?.amount) > 0 && xtraGoods?.[0]?.product?.salePrice || {}
}

// us RDF税费处理 wiki.pageId=1419326232
const _apHandleRDFTaxes = ({
  retail_delivery_fee = {},
  language = {},
  stateId = '' // 州id
} = {}) => {
  // MOCK
  // if (gbCommonInfo?.NODE_SERVER_ENV == 'gray') {
  //   retail_delivery_fee = {
  //     'amount': '16.80',
  //     'amountWithSymbol': '$16.80',
  //     'usdAmount': 16.8,
  //     'usdAmountWithSymbol': '$16.80'
  //   }
  // }
  const amountNotZero = +retail_delivery_fee?.amount > 0
  const rdfConfig = {
    show: amountNotZero,
    amount: amountNotZero ? retail_delivery_fee?.amount : '',
    title: language.SHEIN_KEY_PC_22171 || ''
  }
  // US MN州特殊处理
  if (stateId == '64712') {
    rdfConfig.title = language.SHEIN_KEY_PC_31534 || ''
  }
  return rdfConfig
}

// 切换pending
export const checkPending = ({
  billno,
  isGift,
  isBuyPrime
}) => {
  return `${gbCommonInfo.host}/pay/result/pending?${isGift ? 'giftBillno' : 'billno'}=${billno}&buyPrimeOrder=${isBuyPrime ? 1 : 0}`
}

export function jumpToUserPrime() {
  if (typeof window === 'undefined') return
  const _backUrl = gbCommonInfo.host + '/user/prime?banBack=1'
  if (window.history && history.replaceState) {
    history.replaceState(null, null, _backUrl)
  }
  location.href = _backUrl
}

export function routerReplace(url) {
  if (typeof window === 'undefined' || !url) return
  if (window.history && history.replaceState) {
    history.replaceState(null, null, url)
  }
  location.href = url
}

// 普通订单 下单页apple pay订单详情数据处理
export function getPOLineItems(vm) {
  let price = vm?.checkout?.caculate_info || vm?.checkout?.mall_caculate_info || {}
  let language = vm?.language || {}
  let applePayCashShow = vm?.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
    return +price?.originPrice?.amount > 0 ? [{
      'label': language.SHEIN_KEY_PC_14749,
      'amount': price.originPrice.amount
    }] : []
  }
  const { personalTaxes, companyTaxes } = _apHandleTaxes(vm, price.tax || [])
  const rdfTaxInfo = _apHandleRDFTaxes({
    retail_delivery_fee: price.retail_delivery_fee,
    language,
    stateId: vm?.checkout?.default_address?.stateId
  })

  let lineItems = [{
    'label': language.SHEIN_KEY_PC_14750,
    'amount': price.newSubTotal.amount
  },
  {
    'label': language.SHEIN_KEY_PC_14751,
    'amount': price?.actual_shipping_price?.amount
  }]

  // 购买超省卡费用
  if (+price?.save_card_price?.amount > 0) {
    let obj = { label: language.SHEIN_KEY_PC_27044, amount: price.save_card_price.amount || 0 }
    lineItems.push(obj)
  }
  // 购买会员价
  if (+price.prime_price?.amount > 0) {
    let obj = { label: language.SHEIN_KEY_PC_22967, amount: price.prime_price.amount }
    lineItems.push(obj)
  }
  // us RDF消费税 CO州及MN州
  if (rdfTaxInfo.show) {
    const obj = { label: rdfTaxInfo.title, amount: rdfTaxInfo.amount }
    lineItems.push(obj)
  }

  // isShippingInsuranceChecked: 1 (运费不管是否为 0 展示运费险)
  if (vm.showInsurancePrice?.length) {
    let obj = { label: vm.showReturnInsurance, amount: price.insurancePrice?.amount || 0 }
    lineItems.push(obj)
  }
  if (price.handling_fee && (+price.handling_fee.amount > 0)) {
    let obj = { label: language.SHEIN_KEY_PC_19486, amount: price.handling_fee.amount }
    lineItems.push(obj)
  }
  if (price.giftcard && price.giftcard.giftCardUsedPrice && price.giftcard.giftCardUsedCurrencyAmount > 0) {
    let obj = { label: language.SHEIN_KEY_PC_15587, amount: '-' + price.giftcard.giftCardUsedPrice.amount }
    lineItems.push(obj)
  }
  if (vm.showDiscount) {
    const discountLabel = !!gbCommonInfo?.IS_SUGGESTED ? language.SHEIN_KEY_PC_30556 : language.SHEIN_KEY_PC_14698
    let obj = { label: discountLabel, amount: '-' + vm.discountInfoPrice }
    lineItems.push(obj)
  }
  if (+price.pointPrice.amount > 0) {
    let obj = { label: language.SHEIN_KEY_PC_14707, amount: '-' + price.pointPrice.amount }
    lineItems.push(obj)
  }
  // 付费会员优惠金额
  const primeAmount = price.prime_deduce_price?.amount || price.prime_discount_amount_vo?.amount || 0
  if (+primeAmount > 0) {
    let obj = { label: language.SHEIN_KEY_PC_22970, amount: '-' + primeAmount }
    lineItems.push(obj)
  }
  if (price.onlinePayDiscountInfo && price.onlinePayDiscountInfo.onlinePayDiscountInfo && price.onlinePayDiscountInfo.onlinePayDiscountInfo.isEnjoyedDiscount == 1) {
    let obj = { label: language.SHEIN_KEY_PC_25757, amount: '-' + price.onlinePayDiscountInfo.onlinePayDiscountInfo.discountPrice.amount }
    lineItems.push(obj)
  }
  if (+price.walletPrice.amount > 0) {
    // let obj = {label:language.SHEIN_KEY_PC_15763,amount:'-'+ price.walletPrice.amount};
    let obj = { label: language.SHEIN_KEY_PC_18434, amount: '-' + price.walletPrice.amount }
    lineItems.push(obj)
  }
  if (price.extraPromotion && price.extraPromotion.marketFission && price.extraPromotion.marketFission.isValidated == 1) {
    let obj = { label: language.SHEIN_KEY_PC_16286, amount: '-' + price.extraPromotion.marketFission.amount.amount }
    lineItems.push(obj)
  }
  // 个人税费
  if (personalTaxes?.length) {
    lineItems.push(...personalTaxes)
  }
  lineItems.push({ label: language.SHEIN_KEY_PC_14752, amount: price.grandTotalPrice.amount })
  // 公司税费
  if (companyTaxes?.length) {
    lineItems.push(...companyTaxes)
  }
  return lineItems
}

//普通订单 二次下单页apple pay订单详情
export function getPOALineItems(vm) {
  let orderInfo = vm.checkout?.order || {}
  orderInfo['onlinePayDiscountInfo'] = vm.checkout.caculate_info?.onlinePayDiscountInfo
  const primeGoodsPriceInfo = _apHandlePrimeData(orderInfo?.orderGoodsList || [])
  const xtraGoodsPrice = _apHandleXtraGoodsPrice(orderInfo?.orderGoodsList || [])
  let language = vm.language || {}
  let applePayCashShow = vm?.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
    return +orderInfo?.retailTotallPrice?.amount > 0 ? [{
      'label': language.SHEIN_KEY_PC_14749,
      'amount': orderInfo.retailTotallPrice.amount
    }] : []
  }
  const { personalTaxes, companyTaxes } = _apHandleTaxes(vm, orderInfo.tax || [])
  const rdfTaxInfo = _apHandleRDFTaxes({
    retail_delivery_fee: orderInfo.retail_delivery_fee,
    language,
    stateId: orderInfo?.shipping_state_id
  })

  let lineItems = [{
    'label': language.SHEIN_KEY_PC_14750,
    'amount': primeGoodsPriceInfo?.hasPrime || xtraGoodsPrice?.amount ? orderInfo?.normal_goods_total_price?.amount || 0 : orderInfo?.newSubTotalPrice?.amount || 0
  },
  {
    'label': language.SHEIN_KEY_PC_14751,
    'amount': orderInfo.shippingPrice.amount
  }]
  // isShippingInsuranceChecked: 1 (运费不管是否为 0 展示运费险)
  if (orderInfo.orderExtend.insurancePrice.amount > 0 || (orderInfo.insurance_type != 1 && orderInfo.isShippingInsuranceChecked == 1)) {
    let obj = { label: language.SHEIN_KEY_PC_16676, amount: orderInfo.orderExtend?.insurancePrice?.amount || 0 }
    lineItems.push(obj)
  }
  // 超省卡包金额
  if (+xtraGoodsPrice?.amount > 0) {
    const obj = { label: language.SHEIN_KEY_PC_27044, amount: xtraGoodsPrice?.amount || 0 }
    lineItems.push(obj)
  }

  if (+primeGoodsPriceInfo?.sale_price?.amount > 0) {
    let insTitle = language.SHEIN_KEY_PC_24635

    const obj = { label: insTitle, amount: primeGoodsPriceInfo?.sale_price?.amount || 0 }
    lineItems.push(obj)
  }

  // us RDF消费税 CO州及MN州
  if (rdfTaxInfo.show) {
    const obj = { label: rdfTaxInfo.title, amount: rdfTaxInfo.amount }
    lineItems.push(obj)
  }

  if (orderInfo.handling_fee && (+orderInfo.handling_fee.amount > 0)) {
    let obj = { label: language.SHEIN_KEY_PC_19486, amount: orderInfo.handling_fee.amount }
    lineItems.push(obj)
  }

  if (orderInfo.couponPrice_without_free_shipping && +orderInfo.couponPrice_without_free_shipping.amount > 0) {
    const discountLabel = !!gbCommonInfo?.IS_SUGGESTED ? language.SHEIN_KEY_PC_30556 : language.SHEIN_KEY_PC_14698
    let obj = { label: discountLabel, amount: '-' + orderInfo.couponPrice_without_free_shipping.amount }
    lineItems.push(obj)
  }

  if (orderInfo.pointPrice && +orderInfo.pointPrice.amount > 0) {
    let obj = { label: language.SHEIN_KEY_PC_14707, amount: '-' + orderInfo.pointPrice.amount }
    lineItems.push(obj)
  }
  // 付费会员优惠金额
  const primeAmount = orderInfo.prime_discount_amount_vo?.amount || 0
  if (+primeAmount > 0) {
    let obj = { label: language.SHEIN_KEY_PC_22970, amount: '-' + primeAmount }
    lineItems.push(obj)
  }
  if (orderInfo.onlinePayDiscountInfo && orderInfo.onlinePayDiscountInfo.isEnjoyedDiscount == 1 && orderInfo.onlinePayDiscountInfo.discount_price) {
    let obj = { label: language.SHEIN_KEY_PC_25757, amount: '-' + orderInfo.onlinePayDiscountInfo.discount_price.amount }
    lineItems.push(obj)
  }
  if (orderInfo.usedWalletPrice && orderInfo.usedWalletPrice.amount > 0) {
    // let obj = {label:language.SHEIN_KEY_PC_15763,amount:'-'+ orderInfo.usedWalletPrice.amount};
    let obj = { label: language.SHEIN_KEY_PC_18434, amount: '-' + orderInfo.usedWalletPrice.amount }
    lineItems.push(obj)
  }
  if (orderInfo.giftcard && orderInfo.giftcard.price) {
    let obj = { label: language.SHEIN_KEY_PC_15587, amount: '-' + orderInfo.giftcard.price.amount }
    lineItems.push(obj)
  }
  if (orderInfo.extraPromotion && orderInfo.extraPromotion[0] && orderInfo.extraPromotion[0].type == 1 && orderInfo.extraPromotion[0].amount && orderInfo.extraPromotion[0].amount.amount > 0) {
    let obj = { label: language.SHEIN_KEY_PC_16286, amount: '-' + orderInfo.extraPromotion[0].amount.amount }
    lineItems.push(obj)
  }
  // 个人税费
  if (personalTaxes?.length) {
    lineItems.push(...personalTaxes)
  }
  lineItems.push({ label: language.SHEIN_KEY_PC_14752, amount: orderInfo.totalPrice.amount })
  // 公司税费
  if (companyTaxes?.length) {
    lineItems.push(...companyTaxes)
  }
  return lineItems
}

// 一键购订单 apple pay订单详情
export function getOCPLineItems(vm) {
  const { order = {}, language = {}, calculateMallInfo = {} } = vm || {}
  let abtInfo = vm?.abtInfo || {}
  let applePayCashShow = abtInfo?.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
  // TODO：一键购暂时没有原价字段
  //   return +calculateMallInfo?.originPrice?.amount > 0 ? [{
  //     'label': language.SHEIN_KEY_PC_30490,
  //     'amount': calculateMallInfo.originPrice.amount
  //   }] : []
    return []
  }
  const { personalTaxes } = _apHandleTaxes({
    language,
    checkout: {
      default_address: {
        countryId: order.country_id,
        value: order.countryCode
      }
    }
  }, calculateMallInfo.tax || [])
  const onlinePayDiscountInfo = calculateMallInfo?.onlinePayDiscountInfo?.onlinePayDiscountInfo || {}
  let lineItems = [{
    'label': language.SHEIN_KEY_PC_25332,
    'amount': calculateMallInfo.totalPrice?.amount
  }]

  if (calculateMallInfo.handling_fee && (+calculateMallInfo.handling_fee.amount > 0)) {
    lineItems.push({ label: language.SHEIN_KEY_PC_25240, amount: calculateMallInfo.handling_fee.amount })
  }

  if (onlinePayDiscountInfo?.isEnjoyedDiscount == 1 && onlinePayDiscountInfo.discountPrice?.amount > 0) {
    lineItems.push({ label: language.SHEIN_KEY_PC_25246, amount: '-' + onlinePayDiscountInfo.discountPrice.amount })
  }

  if (calculateMallInfo.extraPromotion?.marketFission?.isValidated == 1 && calculateMallInfo.extraPromotion?.marketFission?.amount?.amount > 0) {
    lineItems.push({ label: language.SHEIN_KEY_PC_25247, amount: '-' + calculateMallInfo.extraPromotion?.marketFission?.amount?.amount })
  }

  // 个人税费
  if (personalTaxes?.length) {
    lineItems.push(...personalTaxes)
  }

  return lineItems
}

// 付费会员单独购订单 apple pay订单详情
export function getPrimeLineItems(vm) {
  const { order = {}, language = {} } = vm || {}
  let abtInfo = vm?.abtInfo || {}
  let applePayCashShow = abtInfo?.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
    // TODO：付费会员暂时没有原价字段
    // return +order?.originPrice?.amount > 0 ? [{
    //   'label': language.SHEIN_KEY_PC_30490,
    //   'amount': order.originPrice.amount
    // }] : []
    return []
  }
  let lineItems = [
    {
      'label': language?.SHEIN_KEY_PC_22975 || 'SHEIN CLUB',
      'amount': order.goods_total?.amount
    },
    {
      'label': language?.SHEIN_KEY_PC_14752 || 'Grand Total',
      'amount': order.totalPrice.amount
    }
  ]
  // 存在税费则需要展示税费
  if (Number(order?.tax_total?.amount) > 0) {
    lineItems.splice(1, 0, {
      'label': language?.SHEIN_KEY_PC_22976 || 'Sales Tax',
      'amount': order.tax_total.amount
    })
  }
  return lineItems
}

// 付费会员单独购订单 apple pay订单详情
export function getPrimeAgainLineItems(vm) {
  const { order = {}, language = {} } = vm || {}
  let abtInfo = vm?.abtInfo || {}
  let applePayCashShow = abtInfo?.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
    // TODO：付费会员暂时没有原价字段
    // return +order?.originPrice?.amount > 0 ? [{
    //   'label': language.SHEIN_KEY_PC_30490,
    //   'amount': order.originPrice.amount
    // }] : []
    return []
  }
  let lineItems = [
    {
      'label': language?.SHEIN_KEY_PC_22975 || 'SHEIN CLUB',
      'amount': order?.subTotalPrice?.amount || 0
    },
    {
      'label': language?.SHEIN_KEY_PC_14752 || 'Grand Total',
      'amount': order.totalPrice.amount
    }
  ]

  const taxPrice = order?.tax?.[0]?.taxPrice || {}
  // 存在税费则需要展示税费
  if (Number(taxPrice?.amount) > 0) {
    lineItems.splice(1, 0, {
      'label': language?.SHEIN_KEY_PC_22976 || 'Sales Tax',
      'amount': taxPrice?.amount
    })
  }
  return lineItems
}

// 礼品卡订单 apple pay订单详情
export function getGiftCardLineItems(vm) {
  const { order = {}, language = {}, originPrice = {} } = vm || {}
  let abtInfo = vm?.abtInfo || {}
  let applePayCashShow = abtInfo?.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
    return +originPrice?.amount > 0 ? [{
      'label': language.SHEIN_KEY_PC_14749,
      'amount': originPrice.amount
    }] : []
  }
  return [{
    'label': language?.SHEIN_KEY_PC_14750 || 'Subtotal',
    'amount': order.currency_total_all
  },
  {
    'label': language?.SHEIN_KEY_PC_14752 || 'Grand Total',
    'amount': order.currency_total_all
  }]
}

// 超省卡单独购一次下单 apple pay订单详情
export function getXtraLineItems(vm) {
  const { order = {}, language = {} } = vm || {}
  let abtInfo = vm?.abtInfo || {}
  let applePayCashShow = abtInfo?.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
    // TODO：超省卡暂时没有原价字段
    // return +order?.originPrice?.amount > 0 ? [{
    //   'label': language.SHEIN_KEY_PC_30490,
    //   'amount': order.originPrice.amount
    // }] : []
    return []
  }
  let lineItems = [
    {
      'label': language?.SHEIN_KEY_PC_27044 || '',
      'amount': order?.goods_total?.amount || 0
    },
    {
      'label': language?.SHEIN_KEY_PC_14752 || 'Grand Total',
      'amount': order?.totalPrice?.amount || 0
    }
  ]
  // 存在税费则需要展示税费
  const taxPrice = order?.tax?.[0]?.taxPrice || {}
  if (Number(taxPrice?.amount) > 0) {
    lineItems.splice(1, 0, {
      'label': language?.SHEIN_KEY_PC_27045 || 'Sales Tax',
      'amount': taxPrice.amount
    })
  }
  return lineItems
}

// 超省卡单独购二次下单 apple pay订单详情
export function getXtraAgainLineItems(vm) {
  const { order = {}, language = {} } = vm || {}
  let abtInfo = vm?.abtInfo || {}
  let applePayCashShow = abtInfo?.applePayCashShow || ''
  if (applePayCashShow == 'Show2') {
    // TODO：超省卡暂时没有原价字段
    // return +order?.originPrice?.amount > 0 ? [{
    //   'label': language.SHEIN_KEY_PC_30490,
    //   'amount': order.originPrice.amount
    // }] : []
    return []
  }
  let lineItems = [
    {
      'label': language?.SHEIN_KEY_PC_27044 || '',
      'amount': order?.subTotalPrice?.amount || 0
    },
    {
      'label': language?.SHEIN_KEY_PC_14752 || 'Grand Total',
      'amount': order?.totalPrice?.amount || 0
    }
  ]
  const taxPrice = order?.tax?.[0]?.taxPrice || {}
  // 存在税费则需要展示税费
  if (Number(taxPrice?.amount) > 0) {
    lineItems.splice(1, 0, {
      'label': language?.SHEIN_KEY_PC_27045 || 'Sales Tax',
      'amount': taxPrice?.amount
    })
  }
  return lineItems
}

/**
 * 替换支付成功页的referer
 *
 * @export
 */
export function replacePaySuccessReferer ({ isGiftCard = false } = {}) {
  const { pathname = '' } = location || {}
  const orderListPath = isGiftCard ? '/user/orders/giftcard' : '/user/orders/list'
  if (pathname !== orderListPath) {
    history.replaceState(null, null, `${gbCommonInfo?.host}${orderListPath}`)
  }
}
