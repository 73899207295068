import { ref } from 'vue'
import schttp from 'public/src/services/schttp'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { checkoutReport as reportBankDialog }  from 'public/src/pages/checkout/analysis/report_bank_dialog.js'
import { fetchTelCountryListApi } from 'public/src/services/api/pay/checkout.js'

export default ({
  updateSelectData
} = {
  updateSelectData: () => {}
}) => {
  const banksConfig = ref({})
  const bankDrawerConfig = ref({
    visible: false,
    bankList: [],
    paymentMethod: '',
    defaultCode: '',
    autoPay: false,
    loaded: false, // 组件是否已加载
  })
  const capitecDialog = ref({
    isCollect: false,
    isDlocalPse: false,
    isDlocalEft: false,
    visible: false,
    phone: '',
    email: '',
    countryId: '',
    bankInfo: {},
    paymentMethod: '',
    phoneCountryNum: 'ZA+27',
    phoneRule: {
      reg: '^[0-9]{5,30}$',
      copywrite: 'Phone Number should contain 5-30 digits no other characters.'
    },
    emailRule: {
      reg: /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$/,
      copywrite: ''
    },
    loaded: false, // 组件是否已加载
    collectEmail: false,
    collectPhoneNumber: false
  })
  const isDelayChangeBanks = ref(false)
  const delayChangeBanksData = ref({})

  const openDelayChangeBanks = () => {
    isDelayChangeBanks.value = true
  }

  const closeDelayChangeBanks = () => {
    isDelayChangeBanks.value = false
  }

  const setDelayChangeBanksData = (nowChangeData) => {
    delayChangeBanksData.value = Object.assign({}, delayChangeBanksData.value, nowChangeData)
  }

  function updateBankDrawerConfig(payload = {}) {
    bankDrawerConfig.value = Object.assign({}, bankDrawerConfig.value, payload)
  }

  function updateBanksConfig(payload = {}) {
    banksConfig.value = Object.assign({}, banksConfig.value, payload)
  }
  function updateCapitecDialog(payload = {}) {
    capitecDialog.value = Object.assign({}, capitecDialog.value, payload)
  }

  async function fetchUserPaymentRelatedInfo(code) {
    let res = await schttp({
      url: '/api/checkout/userPaymentRelatedInfo',
      params: {
        paymentCode: code
      }
    })
    let phone = capitecDialog.value.phone || ''
    let email = await UserInfoManager.get({ key: 'userinfo_email', actionType: 'hooks/useBankSelect' }) || ''
    if (res.code === '0' && res.info) {
      if (res.info.email) email = res.info.email
      if (res.info.phoneNumber) phone = res.info.phoneNumber 
    } 
    updateCapitecDialog({
      visible: true, 
      phone: phone,
      email: email,
      loaded: true
    })
  }

  async function fetchUserEmailInfo(code) {
    let res = await schttp({
      url: '/api/checkout/userPaymentRelatedInfo',
      params: {
        paymentCode: code
      }
    })
    let email = await UserInfoManager.get({ key: 'userinfo_email', actionType: 'hooks/useBankSelect' }) || ''
    if (res.code === '0' && res.info) {
      if (res.info.email) email = res.info.email
    } 
    updateCapitecDialog({
      visible: true,
      email: email,
      loaded: true
    })
  }

  async function fetchUserTelInfo(code) {
    let res = await schttp({
      url: '/api/checkout/userPaymentRelatedInfo',
      params: {
        paymentCode: code
      }
    })
    let phone = capitecDialog.value.phone || ''
    if (res.code === '0' && res.info) {
      if (res.info.phoneNumber) phone = res.info.phoneNumber 
    } 
    updateCapitecDialog({
      visible: true, 
      phone: phone,
      loaded: true
    })
  }

  async function fetchTelCheckRuleApi() {
    let countryId = capitecDialog.value.countryId || '196'
    let res = await schttp({
      url: '/api/checkout/telCheckRule',
      params: {
        country_id: countryId
      }
    })
    if (res.code === '0' && res.info?.result?.regex_list?.length) {
      let phoneRule = res.info.result.regex_list[0]
      updateCapitecDialog({
        phoneRule: phoneRule
      })
    }
  }
  async function fetchTelCountryList() {
    let countryId = capitecDialog.value.countryId || '196'
    const res = await fetchTelCountryListApi({
      country_id: countryId
    })
    if (res.isOk) {
      let phoneCountryNum = `${res.info.value}+${res.info.countrynum}`
      updateCapitecDialog({
        phoneCountryNum: phoneCountryNum
      })
    }
  }

  const handleBankClick = ({ bankItem, callback })=>{
    if (
      bankDrawerConfig.value.paymentMethod === 'dlocal-pse'
    ) {
      updateCapitecDialog({ paymentMethod: bankDrawerConfig.value.paymentMethod, bankInfo: bankItem, isDlocalPse: true, isDlocalEft: false })
      fetchUserEmailInfo(bankDrawerConfig.value.paymentMethod)
    } else if (
      bankDrawerConfig.value.paymentMethod === 'dlocal-eft' &&
      bankItem.name === 'Capitec bank'
    ) {
      updateCapitecDialog({ paymentMethod: bankDrawerConfig.value.paymentMethod, bankInfo: bankItem, isCollect: true, isDlocalEft: true, isDlocalPse: false })
      fetchUserPaymentRelatedInfo(bankDrawerConfig.value.paymentMethod)
      fetchTelCheckRuleApi()
      fetchTelCountryList()
    }else{
      updateCapitecDialog({ bankInfo: {}, isCollect: false, isDlocalEft: false, isDlocalPse: false })
      callback()
    }
  }
  // 支付方式 comfrim 按钮事件
  function handleBankSelect(bankItem = {}) {
    const configValue =
      banksConfig.value[bankDrawerConfig.value.paymentMethod] || {}

    const nowChangeData = {
      [bankDrawerConfig.value.paymentMethod]: {
        ...configValue,
        bankCode: bankItem.code,
        bankCodeText: bankItem.name,
        bankLogo: bankItem.logo,
        error: false
      }
    }

    if (isDelayChangeBanks.value) {
      setDelayChangeBanksData(nowChangeData)
    } else {
      updateBanksConfig(nowChangeData)
    }

    updateSelectData(bankDrawerConfig.value.paymentMethod)

    updateBankDrawerConfig({
      visible: false,
      paymentMethod: bankDrawerConfig.value.paymentMethod,
      defaultCode: bankItem?.code
    })
  }
  // submit: 银行列表 comfrim 按钮事件
  function handleCapitecDialogEvent(payload) {
    reportBankDialog(payload)
    if(payload.type === 'submit'){
      updateCapitecDialog({
        visible: false,
        phone: payload.phone,
        email: payload.email
      })
      handleBankSelect(capitecDialog.value.bankInfo)
    }
  }
  // 弹起收集信息弹框
  function handleTelEmailDialog(bankItem) {
    // 没有银行，弹起收集信息弹框
    // collectEmail:是否收集邮箱
    // collectPhoneNumber:是否收集电话号码
    const { collectEmail = false, collectPhoneNumber = false } = capitecDialog?.value
    if(collectEmail && collectPhoneNumber) {
      updateCapitecDialog({ paymentMethod: bankDrawerConfig.value.paymentMethod, bankInfo: bankItem, isCollect: true })
      fetchUserPaymentRelatedInfo(bankDrawerConfig.value.paymentMethod)
      fetchTelCheckRuleApi()
      fetchTelCountryList()
    } else if(collectEmail) {
      updateCapitecDialog({ paymentMethod: bankDrawerConfig.value.paymentMethod, bankInfo: bankItem })
      fetchUserEmailInfo(bankDrawerConfig.value.paymentMethod)
    } else if(collectPhoneNumber) {
      updateCapitecDialog({ paymentMethod: bankDrawerConfig.value.paymentMethod, bankInfo: bankItem })
      fetchUserTelInfo(bankDrawerConfig.value.paymentMethod)
      fetchTelCheckRuleApi()
      fetchTelCountryList()
    }
  }
  // show 支付列表
  function handleStartSelectBank(payment, autoPay = true, userInfoCheckError = false) {
    let code = ''
    // payment的类型应该为object类型，防止有遗漏的传了string类型（已兼容）
    // 无论payment是什么类型，如果有支付方式里有银行列表，则弹起银行列表
    if(typeof payment === 'object') {
      updateCapitecDialog({
        collectEmail: payment.collect_email == 1,
        collectPhoneNumber: payment.collect_phone_number == 1
      })
      code = payment.code
    } else if(typeof payment === 'string'){
      code = payment
    } else {
      return
    }

    if(userInfoCheckError) {
      // 收集信息弹框
      handleTelEmailDialog(capitecDialog?.value?.bankInfo)
      return
    }

    if(banksConfig?.value?.[code]?.list?.length){
      // 有银行，则弹起银行列表
      updateBankDrawerConfig({
        defaultCode: banksConfig?.value?.[code]?.bankCode || '',
        visible: true,
        bankList: banksConfig?.value?.[code]?.list || [],
        paymentMethod: code,
        autoPay: autoPay,
        loaded: true
      })
    }
  }
  // reset回初始
  function resetBankData() {
    banksConfig.value = {}
    updateBankDrawerConfig({
      visible: false,
      paymentMethod: '',
      bankList: [],
      defaultCode: ''
    })
  }

  const initBankLists = paymentMethods => {
    resetBankData()
    paymentMethods.forEach(payment => {
      if (payment.bank_list && payment.bank_list.length) {
        const banks = payment.bank_list.map((item = {}) => {
          const { code, name } = item || {}
          return {
            ...item,
            value: code,
            label: name
          }
        })
        const backupConfig = banksConfig.value[payment.code] || {}
        const matchSelect =
          banks.find(item => item.code == backupConfig.bankCode) || {}
        updateBanksConfig({
          [payment.code]: {
            list: banks,
            bankCode: matchSelect?.code || '',
            bankCodeText: matchSelect.name || '',
            bankLogo: matchSelect?.logo || '',
            error: false
          }
        })
      }
    })
  }

  return {
    banksConfig,
    bankDrawerConfig,
    capitecDialog,
    updateBankDrawerConfig,
    updateBanksConfig,
    updateCapitecDialog,
    handleBankClick,
    handleBankSelect,
    handleStartSelectBank,
    handleCapitecDialogEvent,
    resetBankData,
    initBankLists,
    openDelayChangeBanks,
    closeDelayChangeBanks,
    setDelayChangeBanksData,
    delayChangeBanksData
  }
}

