<template>
  <div v-if="showInstallmentInfo">
    <!-- 区域:分期支付信息展示 -->
    <div
      class="extra-tip"
      :data-payment-code="item.code"
    >
      <span>
        {{ frontListItemsValue.place_order_desc }}
      </span>
      <a
        v-if="showInstallmentInfoHref"
        :href="frontListItemsValue.place_order_jump_url"
        target="_blank"
        class="extra-tip-link"
        @click.stop
      >
        <Icon
          name="sui_icon_doubt_16px_1"
          size="14px"
          color="#666666"
        />
      </a>
    </div>

    <ItemInfoBnplGraph
      v-if="isShowBnplGraph"
      :scene="scene"
      :options="effectOptions"
      :language="language"
    />
    <!-- 区域:选中提示 -->
    <slot></slot>
  </div>
</template>

<script>
import ItemInfoBnplGraph from './item_info_bnpl_graph.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { Icon } from '@shein-aidc/icon-vue3'
daEventCenter.addSubscriber({ modulecode: '2-31' })
export default {
  name: 'ItemInfoBnpl',
  components: {
    ItemInfoBnplGraph,
    Icon
  },
  props: {
    scene: {
      type: String,
      default: 'checkout'
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    isSelect: {
      type: Boolean,
      default: false
    },
    frontListItemsValue: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    // 【分期数】、【每期付款金额字段】、【还款周期】、【还款周期单位】、【是否首期收费】
    return {
      graphColor: {
        afterpay: '#68DCB6',
        klarna: '#FFA8CD',
        other: '#0286C8'
      }
    }
  },
  computed: {
    showInstallmentInfo() {
      return this.frontListItemsValue?.is_display == 1 && this.item.enabled == 1
    },
    showInstallmentInfoHref() {
      return this.frontListItemsValue?.place_order_jump_url
    },
    isShowBnplGraph() {
      return (
        this.isSelect &&
        this.options?.isShowBNPLinAbt &&
        this.showInstallmentInfo &&
        !this.displayIn30Days &&
        this.isOkRepaymentList &&
        this.isOkAmount
      )
    },
    // 当前支持【2、3、4、5、6 】5种分期
    isOkRepaymentList() {
      let graphInfo = this.frontListItemsValue.visualization
      if (!graphInfo || !graphInfo.repayment_period_list) return false
      return [2, 3, 4, 5, 6].includes(graphInfo.repayment_period_list.length)
    },
    // 当【还款周期】=30且【还款周期单位】=day且【是否首期收费】=否时
    displayIn30Days() {
      let graphInfo = this.frontListItemsValue.visualization
      if (!graphInfo) return true
      return (
        graphInfo.repayment_period == 30 &&
        graphInfo.repayment_period_unit === 'day' &&
        graphInfo.first_period_repay === 0
      )
    },
    // 当总金额为0时不展示
    isOkAmount() {
      return !(+this.options.amount <= 0)
    },
    effectOptions() {
      let graphInfo = this.frontListItemsValue.visualization
      if (!graphInfo) return {}
      let color = this.graphColor[this.frontListItemsValue.bnpl_type]
      return {
        amount: this.options.amount,
        repayment_period: graphInfo.repayment_period,
        repayment_period_unit: graphInfo.repayment_period_unit,
        first_period_repay: graphInfo.first_period_repay,
        repayment_period_list: graphInfo.repayment_period_list,
        // first_period_repay: graphInfo.first_period_repay,
        // repayment_period: 1212310,
        // repayment_period_unit: 'month',
        // repayment_period_list: new Array(6).fill(0).map((v, k) => {
        //   let item = JSON.parse(JSON.stringify(graphInfo.repayment_period_list[0]))
        //   item.current_period = k + 1
        //   return item
        // }),
        color
      }
    }
  },
  emits: ['event'],
  watch: {
    isSelect(val) {
      if (this.showInstallmentInfo && val) {
        this.reportExposeScenesabt()
        if (this.isShowBnplGraph) {
          this.reportExposeBnplComponent()
        }
      }
    },
    isShowBnplGraph(val) {
      if (val) {
        this.changePayInLine()
      }
    }
  },

  methods: {
    changePayInLine() {
      this.$nextTick(() => {
        if (this.isSelect) {
          this.$emit('event', {
            type: 'changePayInLine',
            data: !this.isShowBnplGraph
          })
        }
      })
    },
    reportExposeScenesabt() {
      let graphInfo = this.frontListItemsValue.visualization
      daEventCenter.triggerNotice({
        daId: '2-31-10-1',
        extraData: {
          scenes: 'bnpl_visual_component',
          if_show_bnpl_component: graphInfo ? '1' : '0'
        }
      })
    },
    reportExposeBnplComponent() {
      let graphInfo = this.frontListItemsValue.visualization
      daEventCenter.triggerNotice({
        daId: '2-31-10-2',
        extraData: {
          payment_method: this.item.code,
          installments_number: graphInfo.repayment_period_list?.length
        }
      })
    }
  }
}
</script>
